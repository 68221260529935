/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-bitwise */
import { MouseEvent, ReactElement, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Select, { ActionMeta, MultiValue, OptionsOrGroups, SingleValue } from 'react-select';
import makeAnimated from 'react-select/animated';
import { Button, Card, CardBody, CardHeader, Col, Input, Label, Row, Toast, ToastBody, ToastHeader, UncontrolledTooltip } from 'reactstrap';
import {
  Badge,
  BadgeVariants,
  DatePicker,
  EmptyState,
  FallDetectonIcon,
  GettingOutOfBedIcon,
  Loader,
  Pagination,
  PauseIcon,
  QuestionMarkIcon,
  RailsIcon,
} from '@components';
import { DefaultLayout } from '@containers';
import { AIDetectionsContext, ThemeContext, userContext } from '@context';
import { AiAlertTypeCounts, AiAlertTypes, OrganizationLevels, PatientAiSetting, railOptions, sensitivityTypes, UserRoles } from '@enums';
import { ExclamationCircleIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import { ArrowUpRightIcon, CheckIcon, DocumentMinusIcon, NewspaperIcon, PaintBrushIcon, PhotoIcon, PlayIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { AiAlert, Frame, PatientAiSettingSnapshot, SingleValueProps } from '@interfaces';
import {
  AIDetectionsService,
  AIDetectionsState,
  AIDetectionsSuccessState,
  ErrorState,
  LoadingState,
  SubjectsState,
  SubjectState,
  ThemeService,
  ThemeState,
  UserService,
  UserState,
  useService,
} from '@services';
import { findLabelByValue, getAIConfigDuration, parseInputString } from '@utils';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import moment, { Moment } from 'moment';
import queryString from 'query-string';
import Slider from 'rc-slider';
import { AlertActions } from './AlertActions';
import { Statistics } from './Statistics';

interface AIDetectionsFormProps {
  healthSystem: string;
  room: string;
}

// generate same colors for labels
function getHexColor(str: string) {
  function intToRGB(i: number) {
    const c = (i & 0x00ffffff).toString(16).toUpperCase();
    return `#${'00000'.substring(0, 6 - c.length)}${c}`;
  }

  let hash = 0;
  for (let i = 0; i < str.length; i += 1) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return intToRGB(hash);
}

export const AIDetections = (): ReactElement => {
  const [, userService] = useService<UserState, UserService>(userContext);
  const [aiDetectionsState, aiDetectionsService] = useService<AIDetectionsState, AIDetectionsService>(AIDetectionsContext);
  const [, themeService] = useService<ThemeState, ThemeService>(ThemeContext);
  const [dateError, setDateError] = useState<string>('');
  const [isToastOpen, setIsToastOpen] = useState<boolean>(false);
  const [timeRange, setTimeRange] = useState<number>(aiDetectionsService.timeRange);
  const [isAnimating, setIsAnimating] = useState<boolean>(false);
  const [isPlayingAudio, setIsPlayingAudio] = useState<boolean>(false);
  const [selectedAlert, setSelectedAlert] = useState<AiAlert>({} as AiAlert);
  const [animationSpeed, setAnimationSpeed] = useState<number>(100);
  const [submitError, setSubmitError] = useState<string>('');
  const [drawingsCleared, setDrawingsCleared] = useState<boolean>(false);
  const [showClearDrawings, setShowClearDrawings] = useState<boolean>(false);
  const [selectedAudio, setSelectedAudio] = useState<string>('');
  const animationTimer = useRef<NodeJS.Timeout | null>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const counterRef = useRef<number>(0);
  const lastImageRef = useRef<HTMLImageElement>(new Image());

  const navigate = useNavigate();

  const { handleSubmit } = useForm<AIDetectionsFormProps>({
    reValidateMode: 'onChange',
  });

  const aiDetectionsSuccessState = aiDetectionsState instanceof AIDetectionsSuccessState ? aiDetectionsState : null;
  const isDarkMode = themeService.theme.value === 'dark';
  const alerts = aiDetectionsService.aiAlerts.value;
  const animatedComponents = makeAnimated();

  const frames = useMemo(() => aiDetectionsSuccessState?.aiDetections ?? [], [aiDetectionsSuccessState?.aiDetections]);
  const alertBadges: { type: BadgeVariants; icon: ReactNode; label: string; status: number }[] = [
    {
      type: 'success',
      icon: <CheckIcon className='w-4 h-4 mr-1' />,
      label: 'Acknowledge',
      status: 1,
    },
    {
      type: 'danger',
      icon: <XMarkIcon className='w-4 h-4 mr-1' />,
      label: 'False Alert',
      status: 2,
    },
    {
      type: 'info',
      icon: <QuestionMarkIcon className='mr-1' />,
      label: 'Additional Review Needed',
      status: 3,
    },
  ];

  let currentAudioIndex = 0;

  const handleApplyFilters = () => {
    if (!aiDetectionsService.selectedOrganization.value?.value) {
      setSubmitError('Select an organization first');
      return;
    }
    if (!aiDetectionsService.selectedHealthSystem.value?.value) {
      setSubmitError('Select a health system first');
      return;
    }
    if (!aiDetectionsService.selectedRoom.value?.value) {
      setSubmitError('Select a room first');
      return;
    }
    setTimeRange(0);
    setDateError('');
    const startDate = aiDetectionsService.start.value;
    const endDate = aiDetectionsService.end.value;
    const diff = moment(endDate).diff(startDate, 'days');
    if (diff >= 7) {
      setDateError('Date must be within range of 7 days.');
      return;
    }
    navigate({
      search: `${queryString.stringify({
        levelId: aiDetectionsService.selectedRoom.value?.value
          ? aiDetectionsService.selectedRoom.value.value
          : aiDetectionsService.selectedHealthSystem.value?.value,
        levelType: aiDetectionsService.selectedRoom.value?.value ? OrganizationLevels.ROOM : OrganizationLevels.HEALTHSYSTEM,
        start: aiDetectionsService.start.value.format(),
        end: aiDetectionsService.end.value.format(),
        companyId: aiDetectionsService.selectedOrganization.value?.value,
        healthSystemId: aiDetectionsService.selectedHealthSystem.value?.value,
        roomId: aiDetectionsService.selectedRoom.value?.value,
        ...(aiDetectionsService.selectedHealthSystem.value?.value && { healthSystemId: aiDetectionsService.selectedHealthSystem.value.value }),
        ...(aiDetectionsService.selectedRoom.value?.value && { roomId: aiDetectionsService.selectedRoom.value.value }),
      })}`,
    });
    aiDetectionsService.refreshCounter.next(1);
  };

  const handleSetSelectedOrganization = (values: SingleValue<{ value: string; label: string }>) => {
    aiDetectionsService.selectedHealthSystem.next({ label: 'Select a health system', value: '' });
    aiDetectionsService.selectedRoom.next({ label: 'Select a room', value: '' });
    aiDetectionsService.healthSystems.next([]);
    aiDetectionsService.rooms.next([]);
    aiDetectionsService.selectedOrganization.next({ label: values?.label || '', value: values?.value || '' });
  };

  const handleSetSelectedHealthSystem = (value: SingleValue<SingleValueProps>) => {
    setSubmitError('');
    aiDetectionsService.selectedHealthSystem.next(value);
    aiDetectionsService.levelType.next(OrganizationLevels.HEALTHSYSTEM);
    aiDetectionsService.levelId.next(value?.value || '');
    aiDetectionsService.selectedRoom.next({ label: 'Select a room', value: '' });
  };

  const handleSetAiAlertType = (value: MultiValue<SingleValueProps>, action: ActionMeta<SingleValueProps>) => {
    switch (action.action) {
      case 'select-option':
        aiDetectionsService.aiAlertTypeIds.next(value);
        break;
      case 'remove-value':
        aiDetectionsService.aiAlertTypeIds.next(aiDetectionsService.aiAlertTypeIds.value.filter(v => action.removedValue?.value !== v.value));
        break;
      case 'clear':
        aiDetectionsService.aiAlertTypeIds.next([]);
        break;
      default:
        break;
    }
  };

  const handleSetSelectedRoom = (value: SingleValue<SingleValueProps>) => {
    setSubmitError('');
    aiDetectionsService.selectedRoom.next(value);
    aiDetectionsService.levelType.next(OrganizationLevels.ROOM);
    aiDetectionsService.levelId.next(value?.value || '');
  };

  const handleApplyRange = (data: { start: Moment; end: Moment }) => {
    aiDetectionsService.start.next(data.start);
    aiDetectionsService.end.next(data.end);
  };

  const drawDetection = (ctx: CanvasRenderingContext2D, opts: { x: number; y: number; w: number; h: number; label: string; confidence: string }) => {
    const color = getHexColor(opts.label.toUpperCase());
    ctx.beginPath();
    ctx.strokeStyle = color;
    ctx.lineWidth = 2;
    ctx.roundRect(opts.x, opts.y, opts.w, opts.h, 2);
    ctx.stroke();
    ctx.closePath();

    const label = `${opts.label.toUpperCase()} ${opts.confidence}`;
    ctx.beginPath();
    ctx.font = '12px sans-serif';
    ctx.fillStyle = color;
    const metrics = ctx.measureText(label);
    const actualHeight = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
    const padd = 5;
    ctx.roundRect(opts.x - padd, opts.y - actualHeight + opts.h - padd, metrics.width + 2 * padd, actualHeight + 2 * padd, padd);
    ctx.fill();

    ctx.fillStyle = 'white';
    ctx.fillText(label, opts.x, opts.y + opts.h);
    ctx.closePath();
  };

  const drawSkeleton = (ctx: CanvasRenderingContext2D, opts: { label: string; points: { x: number; y: number; part: string }[] }) => {
    // const color = getHexColor(opts.label.toUpperCase());
    const renderLine = (color: string, parts: string[]) => {
      ctx.strokeStyle = color;
      ctx.lineWidth = 2;
      ctx.beginPath();
      parts.forEach((kp, idx) => {
        const pp = opts.points.find(tt => tt.part === kp);
        if (pp) {
          if (idx === 0) {
            ctx.moveTo(pp.x, pp.y);
          } else {
            ctx.lineTo(pp.x, pp.y);
          }
        }
      });
      ctx.stroke();
    };

    const renderHead = (color: string, parts: string[]) => {
      let minX = 1000000,
        minY = 1000000,
        maxX = 0,
        maxY = 0;
      parts.forEach(kp => {
        const pp = opts.points.find(tt => tt.part === kp);
        if (pp) {
          minX = Math.min(minX, pp.x);
          minY = Math.min(minY, pp.y);
          maxX = Math.max(maxX, pp.x);
          maxY = Math.max(maxY, pp.y);
        }
      });
      const cx = (maxX + minX) / 2;
      const cy = (maxY + minY) / 2;
      const r = Math.max(maxX - minX, maxY - minY) / 1.5;
      ctx.strokeStyle = color;
      ctx.lineWidth = 1;
      ctx.beginPath();
      ctx.arc(cx, cy, r, 0, 2 * Math.PI);
      ctx.stroke();
    };

    renderLine('white', ['leftShoulder', 'leftElbow', 'leftWrist']);
    renderLine('white', ['rightShoulder', 'rightElbow', 'rightWrist']);

    renderLine('white', ['leftHip', 'leftKnee', 'leftAnkle']);
    renderLine('white', ['rightHip', 'rightKnee', 'rightAnkle']);

    renderLine('white', ['leftHip', 'rightHip']);
    renderLine('white', ['leftShoulder', 'rightShoulder']);

    renderLine('white', ['leftShoulder', 'rightShoulder']);
    renderLine('white', ['leftEar', 'leftEye', 'nose', 'rightEye', 'rightEar']);

    renderLine('white', ['leftShoulder', 'leftHip']);

    renderLine('white', ['rightShoulder', 'rightHip']);

    renderHead('white', ['leftEar', 'leftEye', 'nose', 'rightEye', 'rightEar']);
  };

  const drawBed = (ctx: CanvasRenderingContext2D, opts: { label: string; points: { x: number; y: number }[] }) => {
    const color = getHexColor(opts.label.toUpperCase());

    ctx.strokeStyle = color;
    ctx.lineWidth = 2;
    ctx.beginPath();
    opts.points.forEach((kp, idx) => {
      if (idx === 0) {
        ctx.moveTo(kp.x, kp.y);
      } else {
        ctx.lineTo(kp.x, kp.y);
      }
    });
    ctx.closePath();
    ctx.stroke();

    const label = `v2: ${opts.label.toUpperCase()}`;
    ctx.beginPath();
    ctx.font = '12px sans-serif';
    ctx.fillStyle = color;
    const metrics = ctx.measureText(label);
    const actualHeight = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
    const padd = 5;
    const opt = opts.points[0];
    ctx.roundRect(opt.x - padd, opt.y - actualHeight - padd, metrics.width + 2 * padd, actualHeight + 2 * padd, padd);
    ctx.fill();

    ctx.fillStyle = 'white';
    ctx.fillText(label, opt.x, opt.y);
    ctx.closePath();
  };

  const drawFrameDetections = (ctx: CanvasRenderingContext2D, frame: Frame, sx: number, sy: number, scaleDownBy: number) => {
    frame?.detections.forEach(detection => {
      if (detection.location) {
        drawDetection(ctx, {
          x: detection.location.left * sx,
          y: detection.location.top * sy,
          w: Math.abs(detection.location.right - detection.location.left) * sx,
          h: Math.abs(detection.location.bottom - detection.location.top) * sy,
          label: detection.title,
          confidence: `${(detection.confidence * 100).toFixed(0)} %`,
        });
      }
    });
    frame?.skeletons?.forEach(skeleton =>
      drawSkeleton(ctx, {
        label: skeleton.action,
        points: skeleton.keypoints.map(kp => ({
          part: kp.part,
          x: (kp.position.x * frame.info.width) / scaleDownBy,
          y: (kp.position.y * frame.info.height) / scaleDownBy,
        })),
      })
    );

    frame?.beds?.forEach(bed =>
      drawBed(ctx, {
        label: bed.bed,
        points: bed.polygon.map(kp => ({ x: (kp.x * frame.info.width) / scaleDownBy, y: (kp.y * frame.info.height) / scaleDownBy })),
      })
    );
  };

  const handleSetTimeRange = (val: number | number[]) => {
    const frame = aiDetectionsService.framesFromAlert.value.at(typeof val === 'number' ? val : 0);
    const context = canvasRef.current?.getContext('2d');
    if (context && frame) {
      const scaleDownBy = 1.5;
      const sx = frame.info.width / frame.info.modelInputWidth / scaleDownBy;
      const sy = frame.info.height / frame.info.modelInputHeight / scaleDownBy;

      if (canvasRef.current) {
        canvasRef.current.width = frame.info.width / scaleDownBy;
        canvasRef.current.height = frame.info.height / scaleDownBy;
        canvasRef.current.style.width = `${frame.info.width / scaleDownBy}`;
        canvasRef.current.style.height = `${frame.info.height / scaleDownBy}`;
        context.setTransform(1, 0, 0, 1, 0, 0);
        context.translate(0.5, 0.5);
        context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
      }

      drawFrameDetections(context, frame, sx, sy, scaleDownBy);
      if (frame.imageUrl !== '') {
        lastImageRef.current.onload = function () {
          context?.drawImage(lastImageRef.current, 0, 0, frame.info.width / scaleDownBy, frame.info.height / scaleDownBy); // 0.5, 0.5
          drawFrameDetections(context, frame, sx, sy, scaleDownBy);
        };
        lastImageRef.current.src = frame.imageUrl;
      }
    }
  };

  const startAnimationTimer = (interval = 100, alertTimestamp?: number) => {
    if (aiDetectionsService.aiAlerts.value.aiAlerts.length !== 0) {
      if (animationTimer.current) {
        clearInterval(animationTimer.current);
      }
      animationTimer.current = setInterval(() => {
        if (
          (counterRef.current >= aiDetectionsService.framesFromAlert.value.length && animationTimer.current) ||
          (alertTimestamp && counterRef.current >= alertTimestamp)
        ) {
          clearInterval(animationTimer.current || 0);
          animationTimer.current = null;
          setIsAnimating(false);
        } else {
          setTimeRange(counterRef.current);
          handleSetTimeRange((counterRef.current += 1));
        }
      }, interval);
      setIsAnimating(true);
      setAnimationSpeed(interval);
    }
    return () => {
      if (animationTimer.current) {
        clearInterval(animationTimer.current);
      }
      animationTimer.current = null;
      setIsAnimating(false);
    };
  };

  const stopAnimationTimer = () => {
    if (animationTimer.current) {
      clearInterval(animationTimer.current);
    }
    animationTimer.current = null;
    setIsAnimating(false);
  };

  useEffect(() => {
    const getUserOrganizations = async () => {
      const { user } = await userService.getUser();
      if (user.role.some(x => x === UserRoles.BANYAN_USER)) {
        const selectedOrganization = aiDetectionsService.organizations.value.find(x => x.name === 'banyan');
        aiDetectionsService.selectedOrganization.next({ label: selectedOrganization?.name || '', value: selectedOrganization?.id || 'All' });
      }
    };
    getUserOrganizations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (aiDetectionsState instanceof AIDetectionsSuccessState) {
      counterRef.current = 0;
    }
  }, [aiDetectionsState]);

  const handlePageChange = (_pageSize: number, pIndex: number) => aiDetectionsService.alertsPageIndex.next(pIndex);

  const marks = useMemo(() => {
    return aiDetectionsService.framesFromAlert.value
      .map((v, index) => {
        if (v.imageUrl) {
          return index;
        }
        return -1;
      })
      .filter(v => v !== -1)
      .reduce(
        (a, v) => ({
          ...a,
          [v]: <>|</>,
        }),
        {}
      );
  }, [aiDetectionsService.framesFromAlert.value]);

  const goToAlert = async (alert: AiAlert) => {
    stopAnimationTimer();
    let response: Frame[] = [];
    const isLoadDetectionsOn = localStorage.getItem('loadDetections');

    if (isLoadDetectionsOn === 'true') {
      response = frames;
    } else if (selectedAlert.id === alert.id) {
      response = aiDetectionsService.framesFromAlert.value;
    } else {
      response = await aiDetectionsService.collectFrameFromAlert(alert.dateCreated);
    }
    const date = moment.utc(alert.dateCreated).unix() * 1000;
    const rangeIndex = response?.findIndex((frame: Frame) => {
      return frame.timeStamp > date;
    });

    setTimeRange(rangeIndex);
    setSelectedAlert(alert);
    handleSetTimeRange(rangeIndex);
    counterRef.current = rangeIndex;
  };

  const playAlert = async (alert: AiAlert) => {
    setSelectedAlert(alert);
    let response: Frame[] = [];
    const isLoadDetectionsOn = localStorage.getItem('loadDetections');

    if (isLoadDetectionsOn === 'true') {
      response = frames;
    } else if (selectedAlert.id === alert.id) {
      response = aiDetectionsService.framesFromAlert.value;
    } else {
      response = await aiDetectionsService.collectFrameFromAlert(alert.dateCreated);
    }
    const date = moment.utc(alert.dateCreated).unix() * 1000;
    const rangeIndex = response?.findIndex((frame: Frame) => {
      return frame.timeStamp > date;
    });

    let range = rangeIndex;
    if (rangeIndex - 30 <= 0) {
      range = response[0]?.timeStamp;
    } else if (rangeIndex + 30 >= response[response.length - 1]?.timeStamp) {
      range = response[response.length - 1]?.timeStamp;
    }
    setTimeRange(range);
    handleSetTimeRange(range);
    counterRef.current = range;
    startAnimationTimer(animationSpeed, range + 60);
  };

  const handleClearDrawings = () => {
    const scaleDownBy = 1.5;
    const frame = aiDetectionsService.currentFrame.value;
    const ctx = canvasRef.current?.getContext('2d');

    if (drawingsCleared) {
      const sx = frame.info.width / frame.info.modelInputWidth / scaleDownBy;
      const sy = frame.info.height / frame.info.modelInputHeight / scaleDownBy;
      drawFrameDetections(ctx as CanvasRenderingContext2D, frame, sx, sy, scaleDownBy);
      setDrawingsCleared(false);
      return;
    }
    ctx?.clearRect(0, 0, canvasRef.current?.width || 0, canvasRef.current?.height || 0);
    if (aiDetectionsService.currentFrame.value.imageUrl) {
      ctx?.drawImage(lastImageRef.current, 0, 0, frame.info.width / scaleDownBy, frame.info.height / scaleDownBy); // 0.5, 0.5
    }
    setDrawingsCleared(true);
  };

  const handleMouseMoveDrawings = (event: MouseEvent) => {
    const rect = document.getElementById('canvas')?.getBoundingClientRect();
    if (rect) {
      const isOutside = event.clientX < rect.left || event.clientX > rect.right || event.clientY < rect.top || event.clientY > rect.bottom;
      if (isOutside) {
        setShowClearDrawings(false);
      }
    }
  };

  const formatAlertConfig = (alert: AiAlert) => {
    const parsedSnapshots = alert.patientAiSettingSnapshot ? JSON.parse(alert.patientAiSettingSnapshot) : [];
    const configurations: PatientAiSettingSnapshot = parsedSnapshots.length > 0 ? parsedSnapshots[0] : ({} as PatientAiSettingSnapshot);
    const { settingTypeId, value } = configurations;

    switch (settingTypeId) {
      case PatientAiSetting.PATIENT_GETTING_OUT_OF_BED:
        return `- ${findLabelByValue(sensitivityTypes, value as string)}`;
      case PatientAiSetting.RAILS:
        return `- ${parseInputString(railOptions, value as string)}`;
      case PatientAiSetting.PRESSURE_INJURY:
        return `- ${getAIConfigDuration(value as string)}`;
      default:
        return '';
    }
  };

  const getAlertIcon = (alertType: number) => {
    switch (alertType) {
      case AiAlertTypeCounts.fallDetected:
        return <FallDetectonIcon />;
      case AiAlertTypeCounts.railDown:
        return <RailsIcon />;
      case AiAlertTypeCounts.patientGettingOutOfBed:
        return <GettingOutOfBedIcon />;
      default:
        return <PhotoIcon className='w-6 h-6 text-white' />;
    }
  };

  const updateAlertStatus = async (alertId: string, status: number) => {
    const response = await aiDetectionsService.updateAlertStatus(alertId, status);

    if (response instanceof ErrorState) {
      setIsToastOpen(true);
    }
  };

  const playAudio = (alertId: string) => {
    setSelectedAudio(alertId);
    setIsPlayingAudio(true);
    const audioElements = aiDetectionsService.audioFiles.value.get(alertId);

    if (currentAudioIndex < (audioElements?.length || 0)) {
      const currentAudio = audioElements ? audioElements[currentAudioIndex] : null;
      if (currentAudio) {
        setIsPlayingAudio(true);
        currentAudio.play();
        currentAudio.onended = () => {
          currentAudioIndex += 1;
          if (currentAudioIndex === (audioElements?.length || 0)) {
            setIsPlayingAudio(false);
            return;
          }
          playAudio(alertId);
        };
      }
    } else {
      currentAudioIndex = 0;
    }
  };

  const stopAudio = () => {
    if (!selectedAudio) {
      return;
    }
    const audio = aiDetectionsService.audioFiles.value.get(selectedAudio);
    setIsPlayingAudio(false);
    if (audio) {
      audio.forEach(a => {
        a.pause();
        // eslint-disable-next-line no-param-reassign
        a.currentTime = 0;
      });
      setSelectedAudio('');
    }
  };

  return (
    <DefaultLayout>
      <Card className='dark:bg-dark dark:border dark:border-gray-700'>
        <CardHeader className='flex items-center dark:bg-dark-body dark:text-gray-300 dark:border-b-gray-700' style={{ fontSize: '14px' }}>
          Filters
        </CardHeader>
        <CardBody style={{ fontSize: '14px' }}>
          <form onSubmit={handleSubmit(handleApplyFilters)}>
            <Row className='flex items-center'>
              <Col md='4'>
                <SubjectState
                  subject={aiDetectionsService.start || aiDetectionsService.end}
                  render={() => (
                    <DatePicker
                      applyRange={handleApplyRange}
                      dateRange={{ start: aiDetectionsService.start.value, end: aiDetectionsService.end.value }}
                      theme={themeService.theme.value}
                    />
                  )}
                />
              </Col>
              <Col md='2'>
                <SubjectState
                  subject={aiDetectionsService.organizations}
                  render={orgs => {
                    const organizations = orgs.map(x => {
                      return { value: x.id, label: x.name };
                    });
                    return (
                      <SubjectState
                        subject={aiDetectionsService.selectedOrganization}
                        render={selectedOrg => {
                          return (
                            <Select
                              styles={
                                isDarkMode
                                  ? {
                                      control: styles => ({ ...styles, background: '#1e2022', color: '#9ca3af', borderColor: '#6B7280' }),
                                      singleValue: styles => ({ ...styles, color: '#9ca3af' }),
                                      menu: styles => ({
                                        ...styles,
                                        background: '#1e2022',
                                        color: '#9ca3af',
                                        border: '1px solid #6B7280',
                                      }),
                                      option: (styles, { isSelected }) => ({
                                        ...styles,
                                        ':hover': { background: '#374151' },
                                        backgroundColor: isSelected ? '#2684FF' : 'transparent',
                                      }),
                                      input: styles => ({ ...styles, color: isDarkMode ? '#9ca3af' : '#000' }),
                                    }
                                  : {}
                              }
                              defaultValue={selectedOrg}
                              options={organizations}
                              isSearchable
                              value={organizations.find(organization => selectedOrg?.value === organization.value)}
                              onChange={handleSetSelectedOrganization}
                            />
                          );
                        }}
                      />
                    );
                  }}
                />
              </Col>
              <Col md='2'>
                <SubjectState
                  subject={aiDetectionsService.healthSystems}
                  render={healthSystems => {
                    const allHealthSystems = [...healthSystems];
                    return (
                      <SubjectState
                        subject={aiDetectionsService.selectedHealthSystem}
                        render={healthSystem => {
                          const value = aiDetectionsService.queryParams.get('healthSystemId')
                            ? allHealthSystems.find(hs => hs.value === healthSystem?.value)
                            : healthSystem;
                          return (
                            <Select
                              styles={
                                isDarkMode
                                  ? {
                                      control: styles => ({ ...styles, background: '#1e2022', color: '#9ca3af', borderColor: '#6B7280' }),
                                      singleValue: styles => ({ ...styles, color: '#9ca3af' }),
                                      menu: styles => ({
                                        ...styles,
                                        background: '#1e2022',
                                        color: '#9ca3af',
                                        border: '1px solid #6B7280',
                                      }),
                                      option: (styles, { isSelected }) => ({
                                        ...styles,
                                        ':hover': { background: '#374151' },
                                        backgroundColor: isSelected ? '#2684FF' : 'transparent',
                                      }),
                                      input: styles => ({ ...styles, color: isDarkMode ? '#9ca3af' : '#000' }),
                                    }
                                  : {}
                              }
                              defaultValue={healthSystem}
                              options={allHealthSystems}
                              isSearchable
                              isClearable
                              value={value}
                              onChange={handleSetSelectedHealthSystem}
                            />
                          );
                        }}
                      />
                    );
                  }}
                />
              </Col>
              <Col md='2'>
                <SubjectState
                  subject={aiDetectionsService.rooms}
                  render={rooms => {
                    const allRooms = [...rooms];
                    return (
                      <SubjectState
                        subject={aiDetectionsService.selectedRoom}
                        render={room => {
                          const value = aiDetectionsService.queryParams.get('roomId') ? allRooms.find(r => r.value === room?.value) : room;
                          return (
                            <Select
                              styles={
                                isDarkMode
                                  ? {
                                      control: styles => ({ ...styles, background: '#1e2022', color: '#9ca3af', borderColor: '#6B7280' }),
                                      singleValue: styles => ({ ...styles, color: '#9ca3af' }),
                                      menu: styles => ({
                                        ...styles,
                                        background: '#1e2022',
                                        color: '#9ca3af',
                                        border: '1px solid #6B7280',
                                      }),
                                      option: (styles, { isSelected }) => ({
                                        ...styles,
                                        ':hover': { background: '#374151' },
                                        backgroundColor: isSelected ? '#2684FF' : 'transparent',
                                      }),
                                      input: styles => ({ ...styles, color: isDarkMode ? '#9ca3af' : '#000' }),
                                    }
                                  : {}
                              }
                              defaultValue={room}
                              options={allRooms}
                              isSearchable
                              isClearable
                              value={value}
                              onChange={handleSetSelectedRoom}
                              placeholder='Room'
                            />
                          );
                        }}
                      />
                    );
                  }}
                />
              </Col>
              <Col md='2'>
                <Button
                  type='submit'
                  disabled={aiDetectionsState instanceof LoadingState}
                  color={isDarkMode ? '' : 'success'}
                  className='w-full text-white text dark:bg-sky-900 dark:border-gray-700 dark:hover:bg-sky-700 dark:hover:border-gray-500'
                  style={{ fontSize: '14px' }}>
                  Apply
                </Button>
              </Col>
              <Col className='flex items-center mt-3'>
                <SubjectState
                  subject={aiDetectionsService.loadDetections}
                  render={value => (
                    <>
                      <InformationCircleIcon id='info' className='w-4 h-4 text-sky-400' />
                      <Label htmlFor='load-detections' className='dark:text-gray-200 px-0 mb-0 mx-1'>
                        Load detections
                      </Label>
                      <Input
                        id='load-detections'
                        type='checkbox'
                        checked={value}
                        onChange={e => {
                          localStorage.setItem('loadDetections', `${e.target.checked}`);
                          aiDetectionsService.loadDetections.next(e.target.checked);
                        }}
                      />
                      <UncontrolledTooltip target='info'>When this is checked, detections will be loaded when page is loaded.</UncontrolledTooltip>
                    </>
                  )}
                />
              </Col>
            </Row>
            {(submitError || dateError) && (
              <Row className='flex items-center mt-1'>
                <Col md='4' className='w-[682px]'>
                  {dateError && <p className='mb-0 text-sm text-red-500'>{dateError}</p>}
                </Col>
                <Col md='2'>
                  <p className='mb-0 text-sm text-red-500'>{submitError.includes('organization') && submitError}</p>
                </Col>
                <Col md='2'>
                  <p className='mb-0 text-sm text-red-500'>{submitError.includes('health system') && submitError}</p>
                </Col>
                <Col md='2'>
                  <p className='mb-0 text-sm text-red-500'>{submitError.includes('room') && submitError}</p>
                </Col>
                <Col md='2' />
              </Row>
            )}
          </form>
        </CardBody>
      </Card>
      {/** AI DETECTIONS */}
      <Card className='dark:bg-dark dark:border dark:border-gray-700 mt-10' onMouseMove={handleMouseMoveDrawings}>
        <CardHeader className='flex items-center justify-between dark:bg-dark-body dark:text-gray-300 dark:border-b-gray-700' style={{ fontSize: '14px' }}>
          <p className='mb-0'>AI Detections</p>
        </CardHeader>
        <CardBody style={{ fontSize: '14px' }}>
          {aiDetectionsState instanceof ErrorState && (
            <div className='text-red-500 flex items-center space-x-2 justify-center'>
              <ExclamationCircleIcon className='w-5 h-5 ' />
              <p className='mb-0 text-center'>{aiDetectionsState.errorMessage || 'Something went wrong. Try again later.'}</p>
            </div>
          )}
          {!!aiDetectionsService.currentFrame.value && (
            <SubjectsState
              subjects={[aiDetectionsService.currentFrame, aiDetectionsService.framesFromAlert]}
              render={() => (
                <div className='flex pt-2'>
                  <div className='w-full'>
                    <div className='relative'>
                      <canvas
                        id='canvas'
                        ref={canvasRef}
                        className='w-full h-[537px]'
                        style={{ border: '1px solid black', backgroundColor: '#2f353a', borderRadius: '5px' }}
                        onMouseEnter={() => {
                          setShowClearDrawings(true);
                        }}
                        onFocus={() => {
                          setShowClearDrawings(true);
                        }}
                        onBlur={() => {
                          setShowClearDrawings(false);
                        }}
                      />
                      <AnimatePresence>
                        {showClearDrawings && aiDetectionsService.currentFrame.value.detections?.length > 0 && (
                          <motion.div
                            key='clear-drawings'
                            onClick={handleClearDrawings}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            id='drawing-icon'
                            className='text-white transition-all rounded-full p-2 pointer-events-auto bg-[#fff] t dark:bg-sky-900 dark:border-gray-700 dark:hover:bg-sky-700 dark:hover:border-gray-500 absolute right-4 top-4'
                            style={{ fontSize: '14px' }}>
                            {drawingsCleared && <PaintBrushIcon className='w-6 h-6 text-[#000] dark:text-sky-500' />}
                            {!drawingsCleared && <DocumentMinusIcon onClick={handleClearDrawings} className='w-6 h-6 text-[#000] dark:text-sky-500' />}
                            <UncontrolledTooltip target='drawing-icon'>{drawingsCleared ? 'Apply Drawings' : 'Clear Drawings'}</UncontrolledTooltip>
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </div>
                    {aiDetectionsState instanceof LoadingState && <Loader />}
                    {Object.keys(aiDetectionsService.currentFrame.value).length !== 0 &&
                      !(aiDetectionsState instanceof LoadingState) &&
                      !(aiDetectionsState instanceof ErrorState) && (
                        <div>
                          <div className='flex items-center mt-[10px]'>
                            <button
                              type='button'
                              className='mr-[10px] p-2 rounded-[10px] bg-[#343434]'
                              onClick={() => {
                                if (animationTimer.current === null) {
                                  startAnimationTimer(animationSpeed);
                                } else {
                                  stopAnimationTimer();
                                }
                              }}>
                              {!isAnimating ? (
                                <PlayIcon className={classNames('text-white', 'w-6 h-6')} />
                              ) : (
                                <PauseIcon className={classNames('text-white', 'w-6 h-6')} />
                              )}
                            </button>
                            <div className='flex items-center w-full space-x-[10px] ml-[10px]'>
                              <Slider
                                defaultValue={0}
                                min={0}
                                max={aiDetectionsService.framesFromAlert.value.length > 0 ? aiDetectionsService.framesFromAlert.value.length - 1 : 0}
                                marks={marks}
                                step={1}
                                value={timeRange}
                                onChange={val => {
                                  setSelectedAlert({} as AiAlert);
                                  stopAnimationTimer();
                                  counterRef.current = val instanceof Array ? 0 : val;
                                  handleSetTimeRange(counterRef.current);
                                  setTimeRange(val instanceof Array ? 0 : val);
                                  const foundIndex = aiDetectionsService.framesFromAlert.value.findIndex((_, i) => i === (val instanceof Array ? 0 : val));
                                  const foundFrame = aiDetectionsService.framesFromAlert.value[foundIndex];
                                  aiDetectionsService.currentFrame.next(foundFrame);
                                }}
                                railStyle={{
                                  opacity: 0.2,
                                  backgroundColor: '#5390FF',
                                }}
                                trackStyle={{ backgroundColor: '#5390FF' }}
                                handleStyle={{ backgroundColor: '#5390FF', borderColor: '#5390FF', opacity: 1 }}
                              />
                              <div className='flex items-center justify-between'>
                                <p className='dark:text-white mb-0 text-center'>
                                  {moment
                                    .unix((aiDetectionsService.currentFrame.value?.timeStamp || 0) / 1000)
                                    .utc()
                                    .format('YYYY-MMM-DD HH:mm:SS.SSS')}
                                </p>
                                <p className='text-2xl text-[#898989] m-0 h-full text-center'>/</p>
                                <p className='text-[#898989] mb-0 text-center'>
                                  {moment
                                    .unix(
                                      (aiDetectionsService.framesFromAlert.value[aiDetectionsService.framesFromAlert.value.length - 1]?.timeStamp || 0) / 1000
                                    )
                                    .utc()
                                    .format('YYYY-MMM-DD HH:mm:SS.SSS')}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className='flex items-center justify-end  space-x-2'>
                            <div className='flex items-center space-x-2'>
                              <Input
                                className='dark:checked:bg-sky-500 bg-transparent !border-[#747474]'
                                type='radio'
                                name='animation-speed'
                                checked={animationSpeed === 100}
                                onChange={() => startAnimationTimer(100)}
                              />
                              <Label className='dark:text-gray-200 px-0 mb-0'>0.1s</Label>
                            </div>
                            <div className='flex items-center space-x-2'>
                              <Input
                                className='dark:checked:bg-sky-500 bg-transparent !border-[#747474]'
                                type='radio'
                                name='animation-speed'
                                checked={animationSpeed === 500}
                                onChange={() => startAnimationTimer(500)}
                              />
                              <Label className='dark:text-gray-200 px-0 mb-0'>0.5s</Label>
                            </div>
                            <div className='flex items-center space-x-2'>
                              <Input
                                className='dark:checked:bg-sky-500 bg-transparent !border-[#747474]'
                                type='radio'
                                name='animation-speed'
                                checked={animationSpeed === 1000}
                                onChange={() => startAnimationTimer(1000)}
                              />
                              <Label className='dark:text-gray-200 px-0 mb-0'>1s</Label>
                            </div>
                          </div>
                          <div className='detections-table'>
                            <div className='flex items-center border-b border-[#747474]'>
                              <div className='text-[#898989] w-[105px] border-r border-[#747474]'>Time</div>
                              <div className='dark:text-white w-full'>
                                {moment
                                  .unix((aiDetectionsService.currentFrame.value?.timeStamp || 0) / 1000)
                                  .utc()
                                  .format('YYYY-MM-DD HH:mm:ss.SSS')}
                              </div>
                            </div>
                            <div className='flex items-center  border-b border-[#747474]'>
                              <div className='text-[#898989] w-[105px] border-r border-[#747474]'>Image</div>
                              <div className='dark:text-white w-full'>{aiDetectionsService.currentFrame.value?.imageFile || 'N/A'}</div>
                            </div>
                            <div className='flex items-center border-b border-[#747474]'>
                              <div className='text-[#898989] w-[105px] border-r border-[#747474]'>Image Size</div>
                              <div className='dark:text-white w-full'>
                                {aiDetectionsService.currentFrame.value?.info?.width}X{aiDetectionsService.currentFrame.value?.info?.height}
                              </div>
                            </div>
                            <div className='flex items-center border-b border-[#747474]'>
                              <div className='text-[#898989] w-[105px] border-r border-[#747474]'>Model</div>
                              <div className='dark:text-white w-full'>{aiDetectionsService.currentFrame.value?.info?.modelName}</div>
                            </div>
                            <div className='flex items-center border-b border-[#747474]'>
                              <div className='text-[#898989] w-[105px] border-r border-[#747474]'>Model Size</div>
                              <div className='dark:text-white w-full'>
                                {aiDetectionsService.currentFrame.value?.info?.modelInputWidth}x{aiDetectionsService.currentFrame.value?.info?.modelInputHeight}
                              </div>
                            </div>
                            <div className='flex items-center'>
                              <div className='text-[#898989] w-[105px] border-r border-[#747474]'>Thresholds</div>
                              <div className='dark:text-white w-full flex items-center'>
                                {aiDetectionsService.currentFrame.value?.info?.thresholds
                                  .map(
                                    t => `${t.label[0].toUpperCase()}${t.label.substring(1, t.label.length).replace('_', ' ')}: ${(t.value * 100).toFixed(0)}%`
                                  )
                                  .join(', ')}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                  <SubjectsState
                    subjects={[
                      aiDetectionsService.aiAlerts,
                      aiDetectionsService.isAlertsLoading,
                      aiDetectionsService.aiAlertsError,
                      aiDetectionsService.alertsPageIndex,
                    ]}
                    render={() => {
                      const aiAlertTypes: OptionsOrGroups<SingleValueProps, { label: string; options: SingleValueProps[] }> = Object.entries(AiAlertTypes).map(
                        ([k, v]) => ({
                          label: v,
                          value: k,
                        })
                      );

                      return (
                        <div className='min-w-[640px]'>
                          <Card
                            className='dark:bg-dark-body flex justify-between ml-4'
                            style={{
                              maxWidth: 640,
                              height: '100%',
                            }}>
                            <CardHeader className='flex items-center justify-between dark:bg-dark-body dark:text-white border-[1px] !border-b-0 border-[#747474]'>
                              <p className='mb-0'>Alerts Detected</p>
                              <div className='flex items-center space-x-3 w-[350px]'>
                                <p className='mb-0'>Filter: </p>
                                <SubjectState
                                  subject={aiDetectionsService.aiAlertTypeIds}
                                  render={() => (
                                    <Select
                                      className='w-full'
                                      components={animatedComponents}
                                      styles={
                                        isDarkMode
                                          ? {
                                              container: styles => ({
                                                ...styles,
                                                width: '100%',
                                              }),
                                              control: styles => ({
                                                ...styles,
                                                background: '#1e2022',
                                                color: '#9ca3af',
                                                borderColor: '#6B7280',
                                              }),
                                              singleValue: styles => ({ ...styles, color: '#9ca3af' }),
                                              menu: styles => ({
                                                ...styles,
                                                background: '#1e2022',
                                                color: '#9ca3af',
                                                border: '1px solid #6B7280',
                                              }),
                                              option: (styles, { isSelected }) => ({
                                                ...styles,
                                                ':hover': { background: '#374151' },
                                                backgroundColor: isSelected ? '#2684FF' : 'transparent',
                                              }),
                                              input: styles => ({ ...styles, color: isDarkMode ? '#9ca3af' : '#000' }),
                                            }
                                          : {}
                                      }
                                      isMulti
                                      isClearable
                                      isSearchable
                                      menuShouldScrollIntoView
                                      backspaceRemovesValue
                                      escapeClearsValue
                                      options={aiAlertTypes}
                                      closeMenuOnSelect={false}
                                      onChange={handleSetAiAlertType}
                                    />
                                  )}
                                />
                              </div>
                            </CardHeader>
                            <CardBody
                              className={classNames('flex flex-col w-full border-x border-[#747474] rounded-b-md ', alerts.totalCount !== 10 ? 'border-b' : '')}
                              style={{ padding: 0 }}>
                              {aiDetectionsService.aiAlertsError.value && (
                                <p className='mb-0 text-sm text-red-500 text-center'> {aiDetectionsService.aiAlertsError.value}</p>
                              )}
                              {aiDetectionsService.isAlertsLoading.value && <Loader />}
                              {(aiDetectionsService.aiAlerts.value.totalCount === 0 ||
                                Object.keys(aiDetectionsService.aiAlerts.value.aiAlerts || {}).length === 0) &&
                                !(aiDetectionsState instanceof ErrorState) && (
                                  <EmptyState className='flex flex-col items-center my-auto w-full bg-[#fff] dark:bg-transparent'>
                                    <NewspaperIcon className='text-gray-600 dark:text-gray-400 w-10 h-10 mx-auto' />
                                    <span className='font-medium dark:text-gray-400'>No data to be shown.</span>
                                  </EmptyState>
                                )}
                              <SubjectState
                                subject={aiDetectionsService.aiAlerts}
                                render={val => {
                                  return (
                                    <>
                                      {!aiDetectionsService.isAlertsLoading.value &&
                                        val.aiAlerts &&
                                        val.aiAlerts.map(alert => {
                                          const alertTimestamp = moment.utc(alert.dateCreated).unix() * 1000;

                                          return (
                                            <div
                                              key={alert.id}
                                              className={classNames(
                                                'p-3 flex  justify-between border-t border-[#747474] last:border-b',
                                                alert.id === selectedAlert.id ? 'dark:bg-black bg-gray-100' : ''
                                              )}>
                                              <div className='flex items-cneter'>
                                                <div className='p-2 bg-[#323335] rounded-[10px] mr-2 flex items-center'>
                                                  {getAlertIcon(alert.aiAlertType.id)}
                                                </div>
                                                <div className='flex flex-col'>
                                                  <p className='mb-0.5 dark:text-white'>
                                                    {alert.aiAlertType.description} {formatAlertConfig(alert)}
                                                  </p>
                                                  <div className='flex items-center space-x-1'>
                                                    {alertBadges.map(badge => (
                                                      <Badge
                                                        key={badge.type}
                                                        variant={badge.type}
                                                        isActive={alert.status === badge.status}
                                                        onClick={() => {
                                                          updateAlertStatus(alert.id, badge.status);
                                                        }}
                                                        className='px-1'>
                                                        {badge.icon}
                                                        {badge.label}
                                                      </Badge>
                                                    ))}
                                                  </div>
                                                </div>
                                              </div>
                                              <div className='flex flex-col space-y-1'>
                                                <button
                                                  id={`alert-${alert.id}`}
                                                  type='button'
                                                  onClick={() => goToAlert(alert)}
                                                  disabled={alertTimestamp < frames[0]?.timeStamp || alertTimestamp > frames[frames.length - 1]?.timeStamp}
                                                  className='disabled:opacity-75 cursor-not-allowed flex items-center space-x-2 text-[#898989] hover:text-gray-900 dark:hover:text-gray-100 transition-all disabled:hover:text-gray-600'>
                                                  <p className={classNames('mb-0', alert.id === selectedAlert.id ? 'dark:text-white' : '')}>
                                                    {moment.utc(alert.dateCreated).format('MM-DD HH:mm:ss.SSS')}
                                                  </p>
                                                  <ArrowUpRightIcon className='w-4 h-4' />
                                                  {alertTimestamp < frames[0]?.timeStamp ||
                                                    (alertTimestamp > frames[frames.length - 1]?.timeStamp && (
                                                      <UncontrolledTooltip target={`alert-${alert.id}`}>Alert is out of time range.</UncontrolledTooltip>
                                                    ))}
                                                </button>
                                                <div className='flex justify-end items-center'>
                                                  <AlertActions
                                                    alert={alert}
                                                    alertTimestamp={alertTimestamp}
                                                    service={aiDetectionsService}
                                                    frames={aiDetectionsService.framesFromAlert.value}
                                                    isAlertAnimating={isAnimating}
                                                    isPlayingAudio={isPlayingAudio}
                                                    stopAnimationTimer={stopAnimationTimer}
                                                    playAlert={playAlert}
                                                    playAudio={playAudio}
                                                    stopAudio={stopAudio}
                                                    selectedAudio={selectedAudio}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        })}
                                    </>
                                  );
                                }}
                              />
                            </CardBody>
                            {aiDetectionsService.aiAlerts.value.totalCount > 10 && (
                              <Pagination
                                className='pt-4 bg-transparent'
                                pageIndex={aiDetectionsService.alertsPageIndex.value}
                                pageSize={10}
                                maxNumberOfPages={10}
                                totalCount={aiDetectionsService.aiAlerts.value.totalCount || 0}
                                onChange={handlePageChange}
                              />
                            )}
                          </Card>
                        </div>
                      );
                    }}
                  />
                </div>
              )}
            />
          )}
        </CardBody>
      </Card>
      <SubjectState
        subject={aiDetectionsService.aiAlerts}
        render={aiAlerts => (
          <Statistics
            isDarkMode={isDarkMode}
            aiAlertsTypeCounts={aiAlerts.aiAlertTypeCounts}
            error={aiDetectionsService.aiAlertsError.value}
            loadingAlerts={aiDetectionsService.isAlertsLoading.value}
          />
        )}
      />

      <Toast fade isOpen={isToastOpen} className='fixed top-16 right-8 border-[1px] !border-red-500'>
        <ToastHeader
          className='dark:bg-dark !border-b-red-500'
          toggle={() => setIsToastOpen(false)}
          close={
            <motion.div
              whileTap={{
                scale: 0.9,
              }}
              onClick={() => setIsToastOpen(false)}>
              <XMarkIcon className='w-5 h-5 dark:text-white transition-all hover:text-gray-200 hover:ring-1 hover:ring-gray-200 rounded-full' />
            </motion.div>
          }>
          Alert Status
        </ToastHeader>
        <ToastBody className='dark:bg-dark-body dark:text-white rounded-b-md'>Something went wrong, please try again later.</ToastBody>
      </Toast>
    </DefaultLayout>
  );
};
