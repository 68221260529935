import { MultiValue, SingleValue } from 'react-select';
import { AIDetectionsApi, getOrganizations } from '@api';
import { OrganizationLevels } from '@enums';
import { AiAlerts, DeviceModel, Frame, HealthSystemsModel, OrganizationProps, RoomModel, SingleValueProps } from '@interfaces';
import { startOfDay } from '@static';
import { AudioFilesMap } from '@views-pages';
import { AxiosError } from 'axios';
import moment, { Moment } from 'moment';
import { BehaviorSubject, debounceTime, from, merge, Observable, skipWhile, switchMap } from 'rxjs';
import { ErrorState } from '../Helpers/Error.service';
import { LoadingState } from '../Helpers/Loading.service';
import { Service } from '../Service';

export class AIDetectionsState {}

export class AIDetectionsSuccessState {
  constructor(aiDetections: Frame[]) {
    this.aiDetections = aiDetections;
  }

  aiDetections: Frame[];
}

export class AIDetectionsService extends Service<AIDetectionsState> {
  constructor(aiDetectionsApi: AIDetectionsApi) {
    super(new AIDetectionsState());

    this.aiDetectionsApi = aiDetectionsApi;

    getOrganizations()
      .then(organizations => this.organizations.next(organizations))
      .catch((error: unknown) => (error as AxiosError).message);

    this.setValuesFromParams();

    merge(this.refreshCounter)
      .pipe(
        debounceTime(200),
        skipWhile(counter => counter === 0 || Object.keys(this.helloDevice.value || {}).length === 0),
        switchMap(this.collectFilesList)
      )
      .subscribe(state => this.next(state));

    merge(this.refreshCounter, this.filesList)
      .pipe(
        debounceTime(200),
        skipWhile(() => !this.loadDetections.value),
        switchMap(this.collectFrames)
      )
      .subscribe(state => this.next(state));

    merge(this.alertsPageIndex, this.refreshCounter, this.aiAlertTypeIds)
      .pipe(
        debounceTime(200),
        skipWhile(() => Object.keys(this.helloDevice.value || {}).length === 0),
        switchMap(this.collectAlerts)
      )
      .subscribe(alerts => this.aiAlerts.next(alerts));

    if (localStorage.getItem('loadFrames')) {
      this.loadDetections.next(JSON.parse(localStorage.getItem('loadFrames') || ''));
    }
    merge(this.selectedOrganization)
      .pipe(switchMap(this.collectHealthSystems))
      .subscribe(healthSystems => {
        const transformedHealthSystems = (healthSystems as HealthSystemsModel).healthSystems.map(hs => ({ value: hs.id, label: hs.name }));
        this.healthSystems.next(transformedHealthSystems);
      });

    merge(this.selectedHealthSystem)
      .pipe(switchMap(this.collectRooms))
      .subscribe(rooms => {
        const transformedRooms = (rooms as RoomModel).rooms.map(({ room }) => ({ value: room.id, label: room.name }));
        this.rooms.next(transformedRooms);
      });

    merge(this.selectedRoom)
      .pipe(
        skipWhile(v => !v?.value),
        switchMap(this.collectDeviceByLevel)
      )
      .subscribe();

    merge(this.aiAlerts)
      .pipe(
        skipWhile(val => !val),
        switchMap(this.collectAlertAudioSnippets)
      )
      .subscribe();
  }

  aiDetectionsApi: AIDetectionsApi;

  organizations = new BehaviorSubject<OrganizationProps[]>([]);

  selectedOrganization = new BehaviorSubject<SingleValue<{ value: string; label: string }>>({ label: 'Select an Organization', value: '' });

  helloDeviceSerialNumber = new BehaviorSubject<string>('');

  helloDeviceId = new BehaviorSubject<number>(0);

  aiAlerts = new BehaviorSubject<AiAlerts>({} as AiAlerts);

  aiAlertsError = new BehaviorSubject<string>('');

  isAlertsLoading = new BehaviorSubject<boolean>(false);

  alertsPageIndex = new BehaviorSubject<number>(0);

  alertsPageSize = new BehaviorSubject<number>(10);

  start = new BehaviorSubject<Moment>(moment(startOfDay).startOf('day'));

  end = new BehaviorSubject<Moment>(moment(startOfDay).endOf('day'));

  startTime = new BehaviorSubject<number>(0);

  endTime = new BehaviorSubject<number>(0);

  refreshCounter = new BehaviorSubject<number>(0);

  filesList = new BehaviorSubject<{ matchingFiles: string[]; availableImages: Map<string, string> }>(
    {} as { matchingFiles: string[]; availableImages: Map<string, string> }
  );

  healthSystems = new BehaviorSubject<SingleValueProps[]>([]);

  selectedHealthSystem = new BehaviorSubject<SingleValue<SingleValueProps>>({ label: 'Health System', value: '' });

  rooms = new BehaviorSubject<SingleValueProps[]>([]);

  selectedRoom = new BehaviorSubject<SingleValue<SingleValueProps>>({ label: 'Room', value: '' });

  levelType = new BehaviorSubject<number>(0);

  levelId = new BehaviorSubject<string>('');

  helloDevice = new BehaviorSubject<DeviceModel>({} as DeviceModel);

  timeRange = 0;

  queryParams = new URLSearchParams(window.location.search);

  startDate = moment.utc(moment.unix(Number(this.queryParams.get('start'))));

  endDate = moment.utc(moment.unix(Number(this.queryParams.get('end'))));

  cId = this.queryParams.get('companyId');

  serialNumber = this.queryParams.get('helloDeviceSerialNumber');

  currentFrame = new BehaviorSubject<Frame>({} as Frame);

  framesFromAlert = new BehaviorSubject<Frame[]>([]);

  loadDetections = new BehaviorSubject<boolean>((localStorage.getItem('loadDetections') || '') === 'true' || false);

  aiAlertTypeIds = new BehaviorSubject<MultiValue<SingleValueProps>>([]);

  audioFiles = new BehaviorSubject<AudioFilesMap>(new Map());

  collectFilesList = (): Observable<AIDetectionsState> => {
    this.next(new LoadingState());
    const getState = async () => {
      try {
        const detections = await this.aiDetectionsApi.getFilesList(
          this.start.value.toDate(),
          this.end.value.toDate(),
          this.selectedOrganization.value?.value || '',
          this.helloDevice.value.serialNumber.toUpperCase().trim()
        );

        this.filesList.next(detections);
        if (!this.loadDetections.value) {
          this.framesFromAlert.next([]);
          this.currentFrame.next({} as Frame);
        }
        return new AIDetectionsState();
      } catch (error: unknown) {
        return new ErrorState((error as AxiosError).message);
      }
    };

    return from(getState());
  };

  collectFrameFromAlert = async (alertTimestamp: string): Promise<Frame[]> => {
    const date = moment(alertTimestamp, 'YYYY-MM-DD HH:mm:ss.SSS');
    const diffs: number[] = [];

    this.filesList.value.matchingFiles.forEach(file => {
      let timestampFromFile = '';
      if (file.includes('.json')) {
        timestampFromFile = file
          .substring(file.lastIndexOf('logs') + 4)
          .replace('.json', '')
          .replaceAll('/', ' ');
      } else {
        timestampFromFile = file
          .substring(file.lastIndexOf('logs') + 4)
          .replace('.json.gz', '')
          .replaceAll('/', ' ');
      }
      const timestamp = moment(timestampFromFile, 'YYYY-MM-DD HH:mm:ss.SSS');
      diffs.push(date.diff(timestamp, 'm'));
    });

    const lowestDiff = Math.min(...diffs.filter(val => !val.toString().includes('-')));
    const indexOfLowestDiff = diffs.findIndex(val => val === lowestDiff);
    const files = this.filesList.value.matchingFiles.slice(indexOfLowestDiff - 5, indexOfLowestDiff + 5);

    const frames = await this.aiDetectionsApi.getFrames(files, this.filesList.value.availableImages);

    if (!frames.length) {
      return [];
    }

    this.currentFrame.next(frames[0]);
    this.framesFromAlert.next(frames);

    return frames;
  };

  collectFrames = () => {
    this.next(new LoadingState());
    const getFrames = async () => {
      try {
        if (this.filesList.value.matchingFiles?.length && this.loadDetections.value) {
          const frames = await this.aiDetectionsApi.getFrames(this.filesList.value.matchingFiles, this.filesList.value.availableImages);

          this.framesFromAlert.next(frames);
          this.currentFrame.next(frames[0]);
          return new AIDetectionsSuccessState(frames);
        }

        return new AIDetectionsState();
      } catch (error) {
        return new ErrorState((error as AxiosError).message);
      }
    };

    return from(getFrames());
  };

  collectAlerts = (): Observable<AiAlerts> => {
    const getAlerts = async () => {
      try {
        this.aiAlertsError.next('');
        this.isAlertsLoading.next(true);
        if (!this.selectedOrganization.value?.value) {
          return {} as AiAlerts;
        }
        const alerts = await this.aiDetectionsApi.getAlerts(
          this.helloDevice.value.solHelloDeviceId,
          this.start.value.utc().format(),
          this.end.value.utc().format(),
          this.alertsPageIndex.value,
          this.alertsPageSize.value || 10,
          this.aiAlertTypeIds.value.map(v => v.value)
        );
        this.isAlertsLoading.next(false);
        return alerts;
      } catch (error: unknown) {
        this.isAlertsLoading.next(false);
        this.aiAlertsError.next((error as AxiosError).message);
        this.aiAlerts.next({} as AiAlerts);
        return this.aiAlerts.value;
      }
    };

    return from(getAlerts());
  };

  updateAlertStatus = async (alertId: string, status: number) => {
    try {
      const response = await this.aiDetectionsApi.updateAlertStatus(alertId, status);
      if (response.hasSucceeded) {
        const alerts = await this.aiDetectionsApi.getAlerts(
          this.helloDevice.value.solHelloDeviceId,
          this.start.value.utc().format(),
          this.end.value
            .utc()
            .minutes(this.end.value.minutes() - 5)
            .format(),
          this.alertsPageIndex.value,
          this.alertsPageSize.value || 10
        );
        this.aiAlerts.next(alerts);
      }
      return response;
    } catch (error) {
      return new ErrorState((error as AxiosError).message);
    }
  };

  collectHealthSystems = () => {
    const getHealthSystems = async () => {
      try {
        if (this.selectedOrganization.value?.value) {
          const healthSystems = await this.aiDetectionsApi.getHealthSystems(this.selectedOrganization.value?.value || '');

          return healthSystems;
        }

        return [];
      } catch (error) {
        return (error as AxiosError).message;
      }
    };

    return from(getHealthSystems());
  };

  collectRooms = () => {
    const getRooms = async (): Promise<RoomModel | string> => {
      try {
        if (this.selectedHealthSystem.value?.value) {
          const rooms = await this.aiDetectionsApi.getRoomsByLevelId(
            this.selectedOrganization.value?.value || '',
            this.selectedHealthSystem.value?.value || ''
          );

          return rooms;
        }

        return { rooms: [] };
      } catch (error) {
        return (error as AxiosError).message;
      }
    };

    return from(getRooms());
  };

  collectDeviceByLevel = () => {
    const getDeviceFromLevel = async () => {
      try {
        if (this.selectedRoom.value?.value) {
          const helloDevices = await this.aiDetectionsApi.getDeviceFromRoom(
            this.selectedOrganization.value?.value || '',
            OrganizationLevels.ROOM,
            this.selectedRoom.value?.value || ''
          );
          this.helloDevice.next(helloDevices.devices[0]);
        }
      } catch (error) {
        console.error({ error });
      }
    };

    return from(getDeviceFromLevel());
  };

  collectAlertAudioSnippets = () => {
    const getSnippets = async () => {
      try {
        const alertIds = this.aiAlerts.value.aiAlerts?.map(a => a.id);

        const audioFiles = await this.aiDetectionsApi.getAudioFilesList(
          this.selectedOrganization.value?.value || '',
          this.helloDevice.value.serialNumber?.toUpperCase().trim(),
          alertIds || []
        );
        this.audioFiles.next(audioFiles);
      } catch (error) {
        console.error({ error });
      }
    };

    return from(getSnippets());
  };

  setValuesFromParams = () => {
    if ([...this.queryParams.keys()].length !== 0) {
      this.start.next(moment(this.queryParams.get('start')));
      this.end.next(moment(this.queryParams.get('end')));
      this.selectedOrganization.next({ label: this.selectedOrganization.value?.label || '', value: this.cId || '' });
      this.helloDeviceSerialNumber.next(this.serialNumber || '');
      this.selectedHealthSystem.next({ label: this.selectedHealthSystem.value?.label || '', value: this.queryParams.get('healthSystemId') || '' });
      this.selectedRoom.next({ label: this.selectedRoom.value?.label || '', value: this.queryParams.get('roomId') || '' });
      const levelType = this.queryParams.get('levelType');
      if (Number(levelType) === OrganizationLevels.HEALTHSYSTEM) {
        this.levelType.next(Number(this.queryParams.get('levelType')));
        this.levelId.next(this.queryParams.get('levelId') || '');
      } else {
        this.levelType.next(Number(this.queryParams.get('levelType')));
        this.levelId.next(this.queryParams.get('levelId') || '');
      }
      this.refreshCounter.next(this.refreshCounter.value + 1);
    }
  };
}
