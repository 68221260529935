import { ConferencesApi } from '@api';
import { ConferenceModel } from '@interfaces';
import { AxiosError } from 'axios';
import { BehaviorSubject, combineLatest, from, Observable, skipWhile, switchMap } from 'rxjs';
import { ErrorState } from '../Helpers/Error.service';
import { LoadingState } from '../Helpers/Loading.service';
import { Service } from '../Service';

export class SingleConferenceState {}

export class SingleConferenceSuccessState extends SingleConferenceState {
  constructor(conference: ConferenceModel) {
    super();
    this.conference = conference;
  }

  conference: ConferenceModel;
}

export class SingleConferenceService extends Service<SingleConferenceState> {
  constructor(conferencesApi: ConferencesApi, isOngoing: boolean, conferenceId: string) {
    super(new LoadingState());

    this.conferencesApi = conferencesApi;

    this.isOngoing = isOngoing;

    this.conferenceId = conferenceId;

    this.collect().subscribe((state: SingleConferenceState) => this.next(state));

    combineLatest([this.refreshCounter])
      .pipe(
        skipWhile(() => !this.conferenceId),
        switchMap(this.collect)
      )
      .subscribe(state => this.next(state));
  }

  conferencesApi: ConferencesApi;

  isOngoing: boolean;

  conferenceId: string;

  refreshCounter = new BehaviorSubject<number>(0);

  private collect = (): Observable<SingleConferenceState> => {
    this.next(new LoadingState());

    const getState = async () => {
      try {
        if (this.isOngoing) {
          const ongoingConference = await this.conferencesApi.getOngoingConferenceDetais(this.conferenceId);
          return new SingleConferenceSuccessState(ongoingConference);
        }

        const conference = await this.conferencesApi.getConferenceDetails(this.conferenceId);

        if (this.state instanceof SingleConferenceSuccessState && typeof conference === 'undefined') {
          return new ErrorState("Couldn't load information for this conference.");
        }

        return new SingleConferenceSuccessState(conference);
      } catch (error: unknown) {
        return new ErrorState((error as AxiosError).message);
      }
    };

    return from(getState());
  };
}
