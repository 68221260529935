import { ReactElement } from 'react';
import { IconProps } from './Icon.props';

export const SoundWaveIcon = ({ ...rest }: IconProps): ReactElement => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    fill='currentColor'
    width='32px'
    height='32px'
    viewBox='0 0 14 14'
    role='img'
    focusable='false'
    aria-hidden='true'
    {...rest}>
    <path d='m 6.2786083,7 0,-3.209 0.273632,0 0.273633,0 0,3.209 0,3.209 -0.273633,0 -0.273632,0 0,-3.209 z m -0.895524,-0.027 0,-2.0423 0.28607,0.015 0.28607,0.015 0.013,2.0274 0.013,2.0273 -0.299066,0 -0.299066,0 0,-2.0422 z m 1.753176,0.015 0.013,-2.0274 0.28607,-0.015 0.28607,-0.015 0,2.0423 0,2.0423 -0.299066,0 -0.299066,0 0.013,-2.0274 z M 3.6417883,7 l 0,-1.4677 0.273632,0 0.273633,0 0,1.4677 0,1.4677 -0.273633,0 -0.273632,0 0,-1.4677 z m 4.378116,0 0,-1.1716 0.299425,0 0.299425,0 -0.01335,1.1567 -0.01336,1.1567 -0.2860701,0.015 -0.2860699,0.015 0,-1.1716 z m 2.6368207,0 0,-1.1716 0.299425,0 0.299424,0 -0.01335,1.1567 -0.01336,1.1567 -0.28607,0.015 -0.28607,0.015 0,-1.1716 z m -7.9104597,0 0,-0.8706 0.298508,0 0.298507,0 0,0.8706 0,0.8707 -0.298507,0 -0.298508,0 0,-0.8707 z m 1.741296,0 0,-0.8706 0.298508,0 0.298507,0 0,0.8706 0,0.8707 -0.298507,0 -0.298508,0 0,-0.8707 z m 4.427867,0 0,-0.8706 0.273632,0 0.273632,0 0,0.8706 0,0.8707 -0.273632,0 -0.273632,0 0,-0.8707 z m 2.6368207,0 0,-0.7214 0.273632,0 0.273632,0 0,0.7214 0,0.7214 -0.273632,0 -0.273632,0 0,-0.7214 z m -9.7015078,0 0,-0.5721 0.298508,0 0.298508,0 0,0.5721 0,0.5721 -0.298508,0 -0.298508,0 0,-0.5721 z m 7.9104591,0 0,-0.5721 0.2985087,0 0.298508,0 0,0.5721 0,0.5721 -0.298508,0 -0.2985087,0 0,-0.5721 z m -8.746276,-0.012 0.01492,-0.2861 0.248757,0 0.248756,0 0.01492,0.2861 0.01492,0.2861 -0.278598,0 -0.278598,0 0.01492,-0.2861 z m 11.3930507,0 0.01492,-0.2861 0.273632,0 0.273632,0 0.01492,0.2861 0.01492,0.2861 -0.303475,0 -0.303475,0 0.01492,-0.2861 z' />
  </svg>
);
