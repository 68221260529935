import { useEffect, useRef, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import Select, { SingleValue } from 'react-select';
import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { DatePicker, EmptyState, Loader } from '@components';
import { AIStatisticsContext, ThemeContext, userContext } from '@context';
import {
  AiAlertsLabels,
  AIStatisticsColors,
  AIStatisticsLabels,
  AIStatisticsNurseColors,
  AIStatisticsNurseLabels,
  AIStatisticsStatuses,
  LastActivityStatus,
  LastActivityType,
  OrganizationLevels,
  UserRoles,
} from '@enums';
import { ExclamationCircleIcon, NewspaperIcon } from '@heroicons/react/24/outline';
import { SingleValueProps } from '@interfaces';
import {
  AIStatisticsService,
  AIStatisticsState,
  AIStatisticsSuccessState,
  ErrorState,
  LoadingState,
  SubjectState,
  ThemeService,
  ThemeState,
  UserService,
  UserState,
  useService,
} from '@services';
import { ChartOptions, GroupedAlerts } from '@views-pages';
import { DoughnutControllerChartOptions } from 'chart.js';
import classNames from 'classnames';
import { Moment } from 'moment';
import queryString from 'query-string';

type KeyIndex = keyof typeof LastActivityType;

export const AIStatistics = () => {
  const [state, service] = useService<AIStatisticsState, AIStatisticsService>(AIStatisticsContext);
  const [, userService] = useService<UserState, UserService>(userContext);
  const [, themeService] = useService<ThemeState, ThemeService>(ThemeContext);
  const [error, setError] = useState<string>('');
  const [showNurseStats, setShowNurseStats] = useState<boolean>(false);

  const navigate = useNavigate();
  const chartRef = useRef(null);

  const isDarkMode = themeService.theme.value === 'dark';
  const isSuccessState = state instanceof AIStatisticsSuccessState;
  const isLoadingState = state instanceof LoadingState;
  const isErrorState = state instanceof ErrorState;
  const isEmptyState = (isSuccessState && !state.statistics.aiAlertTypeCounts.length) || state instanceof AIStatisticsState;

  const plugins = [
    {
      id: 'drawСircles',
      afterUpdate(chart: any) {
        const arcs = chart.getDatasetMeta(0).data;

        arcs.forEach((arc: any) => {
          // eslint-disable-next-line no-param-reassign
          arc.round = {
            x: (chart.chartArea.left + chart.chartArea.right) / 2,
            y: (chart.chartArea.top + chart.chartArea.bottom) / 2,
            radius: (arc.outerRadius + arc.innerRadius) / 2,
            thickness: (arc.outerRadius - arc.innerRadius) / 2,
            backgroundColor: arc.options.backgroundColor,
          };
        });
      },
      afterDraw: (chart: any) => {
        const { ctx } = chart;

        chart.getDatasetMeta(0).data.forEach((arc: any) => {
          const endAngle = Math.PI / 2 - arc.endAngle;

          ctx.save();
          ctx.translate(arc.round.x, arc.round.y);
          ctx.fillStyle = arc.options.backgroundColor;
          ctx.beginPath();
          ctx.arc(
            arc.round.radius * Math.sin(endAngle),
            arc.round.radius * Math.cos(endAngle),
            arc.round.thickness,

            0,

            2 * Math.PI
          );
          ctx.closePath();
          ctx.fill();
          ctx.restore();
        });
      },
    },
  ];

  const handleApplyRange = (data: { start: Moment; end: Moment }) => {
    service.start.next(data.start);
    service.end.next(data.end);
  };

  const handleApplyFilters = () => {
    if (!service.selectedOrganization.value?.value) {
      setError('Please select an organization.');
      return;
    }
    navigate({
      search: `${queryString.stringify({
        levelId: service.selectedRoom.value?.value ? service.selectedRoom.value.value : service.selectedHealthSystem.value?.value,
        levelType: service.selectedRoom.value?.value ? OrganizationLevels.ROOM : OrganizationLevels.HEALTHSYSTEM,
        ...(service.start.value && { start: service.start.value.format() }),
        ...(service.end.value && { end: service.end.value.format() }),
        ...(service.selectedOrganization.value?.value && { companyId: service.selectedOrganization.value.value }),
        ...(service.selectedHealthSystem.value?.value && { healthSystemId: service.selectedHealthSystem.value.value }),
        ...(service.selectedRoom.value?.value && { roomId: service.selectedRoom.value.value }),
      })}`,
    });
    service.refreshCounter.next(1);
  };

  const handleSetSelectedOrganization = (value: SingleValue<SingleValueProps>) => {
    setError('');
    service.selectedHealthSystem.next({ label: 'Select a health system', value: '' });
    service.selectedRoom.next({ label: 'Select a room', value: '' });
    service.healthSystems.next([]);
    service.rooms.next([]);
    service.selectedOrganization.next(value);
  };

  const handleSetSelectedHealthSystem = (value: SingleValue<SingleValueProps>) => {
    service.selectedHealthSystem.next(value);
    service.levelType.next(OrganizationLevels.HEALTHSYSTEM);
    service.levelId.next(value?.value || '');
    service.selectedRoom.next({ label: 'Select a room', value: '' });
  };

  const handleSetSelectedRoom = (value: SingleValue<SingleValueProps>) => {
    service.selectedRoom.next(value);
    service.levelType.next(OrganizationLevels.ROOM);
    service.levelId.next(value?.value || '');
  };

  const getAlertsByType = () => {
    const newAlerts: GroupedAlerts = {};
    const statistics = isSuccessState ? state.statistics : null;

    statistics?.aiAlertTypeCounts?.forEach(alert => {
      if (!newAlerts[LastActivityType[alert.aiAlertType as KeyIndex]]) {
        newAlerts[LastActivityType[alert.aiAlertType as KeyIndex]] = { count: 0, alertStatus: {}, nurseStatus: {} };
      }
      newAlerts[LastActivityType[alert.aiAlertType as KeyIndex]].count = alert.count;

      alert.statusCount?.forEach(st => {
        const alertKey = Object.keys(AIStatisticsStatuses).find(key => AIStatisticsStatuses[key as keyof typeof AIStatisticsStatuses] === st.status);
        if (alertKey) {
          newAlerts[LastActivityType[alert.aiAlertType as KeyIndex]].alertStatus[alertKey] = st.count;
        }
      });
      alert.activityCount?.forEach(ac => {
        const nurseKey = Object.keys(LastActivityStatus).find(key => LastActivityStatus[key as keyof typeof LastActivityStatus] === ac.activityType);
        if (nurseKey) {
          newAlerts[LastActivityType[alert.aiAlertType as KeyIndex]].nurseStatus[nurseKey] = ac.count;
        }
      });
    });

    return newAlerts;
  };

  useEffect(() => {
    const getUserOrganizations = async () => {
      const { user } = await userService.getUser();
      if (user.role.some(x => x === UserRoles.BANYAN_USER)) {
        const selectedOrganization = service.organizations.value.find(x => x.name === 'banyan');
        service.selectedOrganization.next({ label: selectedOrganization?.name || '', value: selectedOrganization?.id || 'All' });
      }
    };
    getUserOrganizations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Card className='dark:bg-dark dark:border dark:border-gray-700'>
        <CardHeader className='flex items-center dark:bg-dark-body dark:text-gray-300 dark:border-b-gray-700' style={{ fontSize: '14px' }}>
          Filters
        </CardHeader>
        <CardBody style={{ fontSize: '14px' }}>
          <Row className='flex items-center'>
            <Col md='4'>
              <DatePicker theme={themeService.theme.value} applyRange={handleApplyRange} dateRange={{ start: service.start.value, end: service.end.value }} />
            </Col>
            <Col md='2' className='p-1'>
              <SubjectState
                subject={service.organizations}
                render={orgs => {
                  const organizations = orgs.map(x => {
                    return { value: x.id, label: x.name };
                  });
                  return (
                    <SubjectState
                      subject={service.selectedOrganization}
                      render={selectedOrg => {
                        return (
                          <Select
                            styles={
                              isDarkMode
                                ? {
                                    control: styles => ({ ...styles, background: '#1e2022', color: '#9ca3af', borderColor: '#6B7280' }),
                                    singleValue: styles => ({ ...styles, color: '#9ca3af' }),
                                    menu: styles => ({
                                      ...styles,
                                      background: '#1e2022',
                                      color: '#9ca3af',
                                      border: '1px solid #6B7280',
                                    }),
                                    option: (styles, { isSelected }) => ({
                                      ...styles,
                                      ':hover': { background: '#374151' },
                                      backgroundColor: isSelected ? '#2684FF' : 'transparent',
                                    }),
                                    input: styles => ({ ...styles, color: isDarkMode ? '#9ca3af' : '#000' }),
                                  }
                                : {}
                            }
                            defaultValue={service.selectedOrganization.value}
                            options={organizations}
                            isSearchable
                            value={organizations.find(organization => selectedOrg?.value === organization.value)}
                            onChange={handleSetSelectedOrganization}
                          />
                        );
                      }}
                    />
                  );
                }}
              />
            </Col>
            <Col md='2' className='p-1'>
              <SubjectState
                subject={service.healthSystems}
                render={healthSystems => {
                  const allHealthSystems = [...healthSystems];
                  return (
                    <SubjectState
                      subject={service.selectedHealthSystem}
                      render={healthSystem => {
                        const value = service.queryParams.get('healthSystemId') ? allHealthSystems.find(hs => hs.value === healthSystem?.value) : healthSystem;
                        return (
                          <Select
                            styles={
                              isDarkMode
                                ? {
                                    control: styles => ({ ...styles, background: '#1e2022', color: '#9ca3af', borderColor: '#6B7280' }),
                                    singleValue: styles => ({ ...styles, color: '#9ca3af' }),
                                    menu: styles => ({
                                      ...styles,
                                      background: '#1e2022',
                                      color: '#9ca3af',
                                      border: '1px solid #6B7280',
                                    }),
                                    option: (styles, { isSelected }) => ({
                                      ...styles,
                                      ':hover': { background: '#374151' },
                                      backgroundColor: isSelected ? '#2684FF' : 'transparent',
                                    }),
                                    input: styles => ({ ...styles, color: isDarkMode ? '#9ca3af' : '#000' }),
                                  }
                                : {}
                            }
                            defaultValue={healthSystem}
                            options={allHealthSystems}
                            isSearchable
                            isClearable
                            value={value}
                            onChange={handleSetSelectedHealthSystem}
                          />
                        );
                      }}
                    />
                  );
                }}
              />
            </Col>
            <Col md='2' className='p-1'>
              <SubjectState
                subject={service.rooms}
                render={rooms => {
                  const allRooms = [...rooms];
                  return (
                    <SubjectState
                      subject={service.selectedRoom}
                      render={room => {
                        const value = service.queryParams.get('roomId') ? allRooms.find(r => r.value === room?.value) : room;
                        return (
                          <Select
                            styles={
                              isDarkMode
                                ? {
                                    control: styles => ({ ...styles, background: '#1e2022', color: '#9ca3af', borderColor: '#6B7280' }),
                                    singleValue: styles => ({ ...styles, color: '#9ca3af' }),
                                    menu: styles => ({
                                      ...styles,
                                      background: '#1e2022',
                                      color: '#9ca3af',
                                      border: '1px solid #6B7280',
                                    }),
                                    option: (styles, { isSelected }) => ({
                                      ...styles,
                                      ':hover': { background: '#374151' },
                                      backgroundColor: isSelected ? '#2684FF' : 'transparent',
                                    }),
                                    input: styles => ({ ...styles, color: isDarkMode ? '#9ca3af' : '#000' }),
                                  }
                                : {}
                            }
                            defaultValue={room}
                            options={allRooms}
                            isSearchable
                            value={value}
                            isClearable
                            onChange={handleSetSelectedRoom}
                          />
                        );
                      }}
                    />
                  );
                }}
              />
            </Col>
            {/** Will be handled in the future */}
            {/* <Col md='2' className='flex items-center justify-center space-x-4 p-0'>
              <div className='flex items-center space-x-2'>
                <label className='dark:text-gray-400' htmlFor='frames'>
                  Frames
                </label>
                <input id='frames' type='checkbox' onChange={e => service.showFrames.next(e.target.checked)} />
              </div>
              <div className='flex items-center space-x-2'>
                <label className='dark:text-gray-400' htmlFor='logs'>
                  Logs
                </label>
                <input id='logs' type='checkbox' onChange={e => service.showLogs.next(e.target.checked)} />
              </div>
            </Col> */}
            <Col md='2' className='flex items-center justify-end p-1'>
              <Button
                type='button'
                onClick={handleApplyFilters}
                disabled={state instanceof LoadingState}
                color={isDarkMode ? '' : 'success'}
                className='w-full text-white text dark:bg-sky-900 dark:border-gray-700 dark:hover:bg-sky-700 dark:hover:border-gray-500'
                style={{ fontSize: '14px' }}>
                Apply
              </Button>
            </Col>
          </Row>
          {error && (
            <Row>
              <Col md='4' />
              <Col md='2'>
                <p className='mb-0 text-red-500'>{error}</p>
              </Col>
              <Col md='2' />
              <Col md='2' />
              <Col md='2' />
            </Row>
          )}
        </CardBody>
      </Card>

      <Card className='dark:bg-dark dark:border dark:border-gray-700 mt-10'>
        <CardHeader className='flex items-center dark:bg-dark-body dark:text-gray-300 dark:border-b-gray-700' style={{ fontSize: '14px' }}>
          Statistics
          <div className='flex items-center space-x-1 ml-4'>
            <input type='checkbox' checked={showNurseStats} onChange={e => setShowNurseStats(e.target.checked)} />
            <p className='mb-0'>Show Nurse Stats</p>
          </div>
        </CardHeader>
        <CardBody style={{ fontSize: 14 }}>
          {isEmptyState && (
            <EmptyState className='text-center w-full bg-[#fff] dark:bg-dark dark:text-gray-300 dark:border-b-gray-500'>
              <div className='bg-[#fff] dark:bg-dark dark:text-gray-300 dark:border-b-gray-500'>
                <NewspaperIcon className='text-gray-600 dark:text-gray-300 w-10 h-10 mx-auto' />
                <span className='font-medium dark:text-gray-400'>No data to be shown.</span>
              </div>
            </EmptyState>
          )}
          {isErrorState && (
            <div className='text-red-500 flex items-center space-x-2 justify-center'>
              <ExclamationCircleIcon className='w-5 h-5 ' />
              <p className='mb-0'>{state.errorMessage || 'Something went wrong. Try again later.'}</p>
            </div>
          )}
          {isLoadingState && <Loader />}

          <div className='grid grid-cols-3 gap-4'>
            {Object.entries(getAlertsByType()).map(([k, v]) => {
              const accurateAlerts = (showNurseStats ? v.nurseStatus.ACKNOWLEDGED : v.alertStatus.ACCURATE) || 0;
              const falseAlerts = (showNurseStats ? v.nurseStatus.FALSE : v.alertStatus.FALSE) || 0;
              const otherAlerts = (showNurseStats ? v.nurseStatus.FORWARDED : v.alertStatus.OTHER) || 0;

              const data = {
                datasets: [{ data: [accurateAlerts, falseAlerts, otherAlerts], backgroundColor: ['#5f91ff', '#e27c7c', '#c7d9fa'] }],
              };

              const options: ChartOptions<'doughnut', DoughnutControllerChartOptions> = {
                cutout: '85%',
                plugins: {
                  legend: {
                    display: false,
                  },
                },
                layout: {
                  padding: 40,
                },
                borderColor: 'transparent',
              };

              const totalCount = showNurseStats
                ? Object.values(v.nurseStatus || {}).reduce((prev, acc) => prev + acc, 0)
                : Object.values(v.alertStatus || {}).reduce((prev, acc) => prev + acc, 0);

              return (
                <Card className='dark:bg-dark dark:border dark:border-gray-700' key={k}>
                  <CardHeader className='flex items-center dark:bg-dark-body dark:text-gray-300 dark:border-b-gray-700' style={{ fontSize: '14px' }}>
                    {AiAlertsLabels[k as keyof typeof AiAlertsLabels]}
                  </CardHeader>
                  <CardBody className='flex items-center w-full'>
                    <div className='w-[250px]'>
                      <div className='absolute text-center left-[125px] top-[160px] text-base'>
                        <p className='mb-0 text-[#6a6a6c]'>Total</p>
                        <p className='mb-0 text-[#e1e1e2] font-semibold'>{totalCount}</p>
                      </div>
                      <Doughnut ref={chartRef} data={data} options={options} plugins={plugins} />
                    </div>
                    <div className='flex flex-col items-start space-y-3'>
                      {Object.keys(showNurseStats ? AIStatisticsNurseLabels : AIStatisticsLabels).map(val => {
                        return (
                          <div key={val} className='flex items-center space-x-2 w-full'>
                            <div
                              className={classNames('w-5 h-5 rounded-md')}
                              style={{
                                background: showNurseStats
                                  ? AIStatisticsNurseColors[+val as keyof typeof AIStatisticsNurseColors]
                                  : AIStatisticsColors[+val as keyof typeof AIStatisticsColors],
                              }}
                            />
                            <div className='flex flex-col text-base'>
                              <p className='mb-0 text-[#99999b] font-medium'>
                                {showNurseStats
                                  ? AIStatisticsNurseLabels[+val as keyof typeof AIStatisticsNurseLabels]
                                  : AIStatisticsLabels[+val as keyof typeof AIStatisticsLabels]}
                              </p>
                              <p className='mb-0 text-[#e1e1e2] font-semibold'>
                                {showNurseStats
                                  ? v.nurseStatus[Object.entries(LastActivityStatus)?.find(([, value]) => value === +val)?.[0] || 0] || 0
                                  : v.alertStatus[Object.entries(AIStatisticsStatuses)?.find(([, value]) => value === +val)?.[0] || 0] || 0}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </CardBody>
                </Card>
              );
            })}
          </div>
        </CardBody>
      </Card>
    </>
  );
};
